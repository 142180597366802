import React, { useEffect, useState } from "react"
import { CustomHeader } from "../../../components/headers/CustomHeader"
import {
  Button,
  CardBody,
  IconButton,
  Input,
  Spinner,
  Textarea,
  Typography,
  DialogFooter,
} from "@material-tailwind/react"
import { BiPlus, BiTrash } from "react-icons/bi"
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai"
import { useForm, SubmitHandler } from "react-hook-form"
import { useMutation, useQuery } from "@apollo/client"

import {
  GETALLBRANCHES,
  GETALLDIVISIONSORWORKGROUPS,
  GETALLEXCLUSIONS,
  GETALLPERILS,
  GETALLPERILSNEXCLUSIONSOFCOVERTYPE,
  GETCOMPANYROLES,
  GETSTAFFBRANCHESANDDIVISIONSFORAGENTS,
  GETALlUSERCOMMISSIONS,
  GETALLCOMMMISSIONS,
} from "../../../GraphQl/queries"
import { ExclusionType, PerilType } from "../../../types/query"
import { toast } from "react-toastify"
import {
  ADDPERILOREXCLUSIONTOCOVERTYPE,
  REMOVECOMMISSION,
  ADDCOMMISSIONTOUSER,
} from "../../../GraphQl/mutations"
import { BASEURL, DEFAULTPRIMARYCOLOR } from "../../../util"
import { DASHBOARD_USERTYPE } from "../../../types/role"
import { CustomModal } from "../../../components"
import { ToastContainer } from "react-toastify"
import { UserType } from "../../../types/user"
import { FaArrowRight } from "react-icons/fa6"
import { DriverModal } from "../../../components/modal/DriverModal"
import Otp from "../../../components/modal/enterOtpModal"
import { useSelector } from "react-redux"
import { RootState } from "../../../store/store"
import WhitePaginateComponent from "../../../components/paginations/whiteBackgroundPagination"

type ResponseType = {
  staff_id: string
  division: {
    id: string
    name: string
    description: string
  }
  branch: {
    id: string
    name: string
    code: string
  }
  id: string
}

type BranchType = {
  address: string
  code: string
  name: string
  id: string
}

type DivisionType = {
  id: string
  name: string
  description: string
}

type CommissionType = {
  id: string
  settlement_mode: string
  commision: {
    id: string
    name: string
  }
}

const ManageAgent = () => {
  const getStorageData = localStorage.getItem("agent")
  const [branchquery, setbranchquery] = useState("")
  const [divisionquery, setDivison] = useState("")
  const [requestLoading, setRequestLoading] = useState(false)
  const [role, setRole] = useState<string | undefined>("")
  const [selectedBranch, setselectedBranch] = useState<string | null>("")
  const [selectedDivision, setselectedDivision] = useState<string | null>("")
  const [showModal, setShowModal] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [searchTextCom, setSearchTextCom] = useState("")
  const [activePage, setActivePage] = useState(1)
  const [openOtp, setOpenOtp] = useState(false)
  const [loadingSendPassword, setLoadingSendPassword] = useState(false)
  const [agentData, setAgentData] = useState(() => {
    return JSON.parse(localStorage.getItem("agent") || "{}")
  })

  const {
    companyId,
    roleId,
    usertype,
    user: stateUser,
    token,
  } = useSelector((state: RootState) => state.auth)

  const [selectedAgent, setAgent] = useState<{
    id: string
    name: string
    intermediary_type: string
    phone_number: string
    branch: string
    role_id: string
  }>()
  const PAGE_SIZE = 10

  const { data: roles, loading: loadingRoles } = useQuery<{
    company_role: {
      created_at: string
      description: string
      id: string
      name: string
    }[]
  }>(GETCOMPANYROLES, {
    variables: {
      id: companyId,
    },
  })

  const {
    data: branches,
    loading: loadingBranch,
    refetch: refetchBranches,
  } = useQuery<{
    branch: BranchType[]
  }>(GETALLBRANCHES)

  const {
    data: divisions,
    loading: loadingDivisions,
    refetch: refetchDivisions,
  } = useQuery<{
    divisions: DivisionType[]
  }>(GETALLDIVISIONSORWORKGROUPS, {
    variables: {
      id: companyId,
    },
  })

  const { data, loading, refetch } = useQuery<{
    staff_branches_divisions: ResponseType[]
  }>(GETSTAFFBRANCHESANDDIVISIONSFORAGENTS, {
    variables: {
      agent_id: selectedAgent?.id,
    },
  })

  const {
    data: commissionsData,
    loading: loadingCommissions,
    refetch: refetchCommissions,
  } = useQuery<{
    user_commission_setup: CommissionType[]
  }>(GETALlUSERCOMMISSIONS, {
    variables: {
      id: selectedAgent?.id,
    },
  })

  const { data: commissions, loading: loadingCommissionsAll } = useQuery<{
    commisions: {
      description: string
      id: string
      name: string
      status: string
      created_at: string
    }[]
  }>(GETALLCOMMMISSIONS,{
    variables:{
      id:companyId
    }
  })

  type FormType = {
    commisions_setup: [
      {
        commission_id: string
        settlement_mode: string
      }
    ]
  }

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { isSubmitting },
  } = useForm<FormType>({
    defaultValues: {
      commisions_setup: [
        {
          commission_id: "",
          settlement_mode: "",
        },
      ],
    },
  })

  const [CreateDiscountRate, { loading: RequestLoading }] = useMutation(
    ADDCOMMISSIONTOUSER,
    {
      onCompleted: (data) => {
        toast.success("Commission Successfully added")
        reset()
        refetchCommissions()
      },
      onError: (error) => {
        if (
          error.message.includes(
            "duplicate key value violates unique constraint"
          )
        ) {
          toast.error(
            "A record with this user and commission type already exists."
          )
        } else {
          toast.error(error?.message ?? "Error creating discount rate")
        }
      },
    }
  )

  const [DeleteCoommission] = useMutation(REMOVECOMMISSION, {
    onCompleted: (data) => {
      toast.success("Commission removed successfully")
      refetchCommissions()
    },
    onError: (error) => toast.error(error?.message ?? "Error deleting class"),
  })

  const deleteClass = (id: string) => {
    try {
      DeleteCoommission({
        variables: {
          id,
          user_id: selectedAgent?.id,
        },
      })
    } catch (error: any) {
      toast.error(error?.message ?? "Error removing commission")
    }
  }
  async function sendAgentPassword() {
    try {
      setLoadingSendPassword(true)
      const res = await fetch(
        `${process.env.REACT_APP_BASE_URL}/send-agent-password?agent_number=${agentData?.number}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      // console.log(res)
      if (!res.ok) {
        toast.error("Error sending password")
        return
      }
      toast.success("Password sent successfully")
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingSendPassword(false)
    }
  }

  const CommissiononSubmit: SubmitHandler<FormType> = (data) => {
    try {
      CreateDiscountRate({
        variables: {
          user_id: selectedAgent?.id,
          commission_id: data.commisions_setup[0].commission_id,
          settlement_mode: data.commisions_setup[0].settlement_mode,
        },
      })
    } catch (error: any) {
      toast.error(error?.message ?? "Error creating commission")
    }
  }

  const assignedBranchIds = new Set(
    data?.staff_branches_divisions.map((item) => item.branch?.id)
  )
  const assignedDivisionIds = new Set(
    data?.staff_branches_divisions.map((item) => item.division?.id)
  )
  const filteredBranches = branches?.branch.filter(
    (branch) => !assignedBranchIds.has(branch.id)
  )
  const filteredDivisions = divisions?.divisions.filter(
    (division) => !assignedDivisionIds.has(division.id)
  )

  const filterData = (filter: ResponseType) => {
    const isTextMatch =
      filter?.branch?.code
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.branch?.name
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.division?.name
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.division?.description
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase())
    return isTextMatch
  }

  const filterCommision = (filter: CommissionType) => {
    const isTextMatch =
      filter?.commision?.name?.toLowerCase()?.includes(searchTextCom?.toLowerCase()) ||
    
      filter?.commision?.name?.toLowerCase()?.includes(searchTextCom?.toLowerCase())
    return isTextMatch
  }

  const filterBranch = (filter: BranchType) => {
    const isTextMatch =
      filter?.address?.toLowerCase()?.includes(branchquery?.toLowerCase()) ||
      filter?.name?.toLowerCase()?.includes(branchquery?.toLowerCase()) ||
      filter?.code?.toLowerCase()?.includes(branchquery?.toLowerCase())
    return isTextMatch
  }

  const filterDivision = (filter: DivisionType) => {
    const isTextMatch =
      filter?.description
        ?.toLowerCase()
        ?.includes(divisionquery?.toLowerCase()) ||
      filter?.name?.toLowerCase()?.includes(divisionquery?.toLowerCase())
    return isTextMatch
  }

  const handleSearch = (e: any) => {
    setSearchText(e.target.value)
    setActivePage(1)
  }

  const handleSearchCom = (e: any) => {
    setSearchTextCom(e.target.value)
    setActivePage(1)
  }

  const handleBranchSearch = (e: any) => {
    setbranchquery(e.target.value)
  }

  const handleDivisionSearch = (e: any) => {
    setDivison(e.target.value)
  }

  // Filtered and paginated data for the current page

  const [currentPage, setCurrentPage] = useState(0)
  const [itemsPerPage] = useState(10)

  useEffect(() => {
    if (searchText) {
      setCurrentPage(0)
    }
  }, [searchText])

 
  const totalItems =data?.staff_branches_divisions
  ?.filter(filterData)
   
  // const paginatedData = totalItems?.slice(startIndex, endIndex)


  const endOffset = currentPage + itemsPerPage
  const paginatedData = totalItems?.slice(currentPage, endOffset)


  const [currentPageCom, setCurrentPageCom] = useState(0)
  const [itemsPerPageCom] = useState(10)

  useEffect(() => {
    if (searchTextCom) {
      setCurrentPageCom(0)
    }
  }, [searchTextCom])

 
  const totalItemsCom =commissionsData?.user_commission_setup?.filter(filterCommision)
   
  // const paginatedData = totalItems?.slice(startIndex, endIndex)


  const endOffsetCom = currentPage + itemsPerPage
  const paginatedDataCom = totalItemsCom?.slice(currentPageCom,  endOffsetCom)


 

  const paginatedBranch = filteredBranches?.filter(filterBranch)

  const paginatedDivision = filteredDivisions?.filter(filterDivision)

  const nextPage = () => {
    if (
      (activePage - 1) * PAGE_SIZE <
      (data?.staff_branches_divisions?.filter(filterData).length || 0)
    ) {
      setActivePage(activePage + 1)
    }
  }

  const prevPage = () => {
    if (activePage > 1) {
      setActivePage(activePage - 1)
    }
  }

  async function sendStaffPassword() {
    try {
      setLoadingSendPassword(true)
      const res = await fetch(
        `${process.env.REACT_APP_BASE_URL}/send-staff-password?staff_number=`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      // console.log(res)
      if (!res.ok) {
        toast.error("Error sending password")
        return
      }
      toast.success("Password sent successfully")
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingSendPassword(false)
    }
  }

  const onSubmit = async (id: string, type: string) => {
    if (!selectedDivision && !selectedBranch) {
      return toast.error("Branch and Division required")
    }
    try {
      setRequestLoading(true)
      let options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          division_id: type === "division" ? id : selectedDivision,
          role_id: selectedAgent?.role_id,
          agent_id: selectedAgent?.id,
          branch_id: type === "branch" ? id : selectedBranch,
          usertype: DASHBOARD_USERTYPE.company,
        }),
      }

      const req = await fetch(`${BASEURL}/add-branches-division-agent`, options)

      const res = await req.json()

      if (req.ok) {
        refetch()
        toast.success(res?.message ?? "Branch and Division Successfully added")
        setselectedDivision("")
        setselectedBranch("")
        refetchBranches()
        refetchDivisions()
      } else {
        toast.error(res?.message ?? "An error occured")
      }
      setRequestLoading(false)
    } catch (error: any) {
      setRequestLoading(false)
      toast.error(error?.message)
    }
  }

  useEffect(() => {
    if (getStorageData) {
      setAgent(JSON.parse(getStorageData))
      refetch()
    }
  }, [])

  useEffect(() => {
    const role = roles?.company_role?.find(
      (item) => item?.id === selectedAgent?.role_id
    )
    setRole(role?.name)
  }, [data?.staff_branches_divisions, selectedAgent])

  const handleDelete = async (record_id: string) => {
    try {
      let options = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          record_id: record_id,
          usertype: DASHBOARD_USERTYPE.company,
        }),
      }

      const req = await fetch(
        `${BASEURL}/delete-branches-division-agent`,
        options
      )

      const res = await req.json()

      if (req.ok) {
        refetch()
        toast.success(
          res?.message ?? "Branch and Division deleted Successfully"
        )
      } else {
        toast.error(res?.message ?? "An error occured")
      }
    } catch (error: any) {
      toast.error(error?.message)
    }
  }

  const userData = localStorage.getItem("user")
  const [colors, setColors] = useState<string[]>()

  useEffect(() => {
    if (userData) {
      const data: UserType = JSON.parse(userData)

      setColors(data.colors)
    }
  }, [])

  return (
    <>
      <CustomHeader title="Manage Agent" />

      <DriverModal
        title="Enter Your PIN"
        open={openOtp}
        size={"xs"}
        focus="message"
        onClose={() => setOpenOtp(false)}
        children={
          <Otp
            onConfirm={() => setShowModal(true)}
            onClose={() => setOpenOtp(false)}
          />
        }
        
      />

      <CustomModal
        onClose={() => setShowModal(false)}
        open={showModal}
        size={"sm"}
        title="Add Commission Type"
        children={
          <>
            <ToastContainer />
            <div>
              <form onSubmit={handleSubmit(CommissiononSubmit)}>
                <div className="mb-6 flex flex-col  gap-6 capitalize">
                  <div className="w-full flex flex-col my-2">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className=" font-semibold text-[14px] w-1/3"
                    >
                      Commission Type
                    </Typography>
                    <div className="w-full flex flex-col gap-2 my-1">
                      <select
                        {...register(`commisions_setup.0.commission_id`, {
                          required: true,
                        })}
                        className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option selected defaultChecked>
                          {loadingCommissions
                            ? "Loading..."
                            : "Select commission"}
                        </option>
                        {commissions?.commisions?.map((item) => (
                          <option
                            key={item?.id}
                            value={item?.id}
                            label={`${item?.name}`}
                          >
                            {item?.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="w-full flex flex-col gap-2 my-1">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className=" font-semibold text-[14px] w-1/3"
                      >
                        Mode of settlement
                      </Typography>
                      <select
                        placeholder="Mode of settlement"
                        {...register(`commisions_setup.0.settlement_mode`, {
                          required: true,
                        })}
                        className="h-11 capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      >
                        <option selected defaultChecked>
                          {loadingCommissions
                            ? "Loading..."
                            : "Select settlement mode"}
                        </option>
                        <option
                          label="Instant"
                          className="capitalize"
                          value={"Instant"}
                        >
                          Instant
                        </option>
                        <option
                          label="manual"
                          className="capitalize"
                          value={"manual"}
                        >
                          Manual
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <DialogFooter>
                  <Button
                    variant="text"
                    color="red"
                    onClick={() => setShowModal(false)}
                    className="mr-1"
                  >
                    <span>Cancel</span>
                  </Button>
                  <Button
                    type="submit"
                    className="flex items-center justify-center"
                    variant="gradient"
                    color="green"
                  >
                    {isSubmitting ? (
                      <Spinner className="h-4 w-4 text-white" />
                    ) : (
                      <span>Add</span>
                    )}
                  </Button>
                </DialogFooter>
              </form>
            </div>
          </>
        }
      />

      <div className="my-5">
        <CardBody className="shadow-xl overflow-x-scroll px-0 py-0  rounded-md ">
          <table className=" bg-white w-full min-w-max table-auto text-left">
            <thead className="">
              <tr>
                {["Name", "Role", "Phone number", "branch ", " "].map(
                  (head) => (
                    <th
                      key={head}
                      className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                    >
                      <Typography
                        color="black"
                        className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                      >
                        {head}
                      </Typography>
                    </th>
                  )
                )}
              </tr>
            </thead>
            <tbody>
              <tr className="transition-all bg-gray-300">
                <td className="px-4 py-3 border-b-2 border-blue-gray-50 text-left">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal text-sm capitalize "
                  >
                    {selectedAgent?.name}
                  </Typography>
                </td>
                <td className="px-4 py-3 border-b-2 border-blue-gray-50 text-left">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal text-sm capitalize "
                  >
                    {role}
                  </Typography>
                </td>
                <td className="px-4 py-3 border-b-2 border-blue-gray-50 text-left">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal text-sm capitalize "
                  >
                    {selectedAgent?.phone_number}
                  </Typography>
                </td>
                <td className="px-4 py-3 border-b-2 border-blue-gray-50 text-left">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal text-sm capitalize "
                  >
                    {selectedAgent?.branch}
                  </Typography>
                </td>
                <td className="px-2 py-3 border-b-2 border-blue-gray-50 ">
                  <Button
                    onClick={sendAgentPassword}
                    // onClick={() => {
                    //   history.push("/dashboard/i2i/manage-driver")
                    //   //()
                    // }}
                    variant="filled"
                    color="green"
                    className="font-normal text-xs w-28 py-1 capitalize"
                  >
                    {loadingSendPassword ? (
                      <Spinner className="w-4 h-4 text-white" />
                    ) : (
                      "Resend password"
                    )}
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        </CardBody>
      </div>

      <div className="w-full flex flex-col md:flex-row lg:flex-row gap-5">
        <div className=" rounded-lg overflow-hidden w-full  lg:w-3/4 ">
          <div className="capitalize rounded-b-xl mb-5">
            <div className="bg-black p-3 text-white">
              <Typography className="text-lg">
                Branches and Divisions{" "}
                <span className="text-sm text-brand-red">
                  (Select branch and divison to add)
                </span>
              </Typography>
            </div>
            <div className="p-3 bg-white">
              <div className="w-full mr-3">
                <Input
                  crossOrigin="anonymous"
                  label="Search"
                  value={branchquery}
                  onChange={handleBranchSearch}
                  icon={<i className="fas fa-search text-base"></i>}
                />
              </div>
            </div>
            <table className=" bg-white w-full min-w-max table-auto text-left">
              <thead className="">
                <tr>
                  {["Branch", "Description", ""].map((head) => (
                    <th
                      key={head}
                      className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                    >
                      <Typography
                        color="black"
                        className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {paginatedBranch?.map((item) => (
                  <tr
                    // onClick={() => {
                    //   if (selectedBranch === item?.id) {
                    //     setselectedBranch(null)
                    //   } else {
                    //     setselectedBranch(item?.id)
                    //   }
                    // }}
                    key={item.id}
                    className={`transition-all hover:cursor-pointer hover:bg-gray-100 `}
                  >
                    <td className="px-4 py-3 border-b-2 border-blue-gray-50 text-left">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-sm capitalize "
                      >
                        {item?.name} (${item?.code})
                      </Typography>
                    </td>
                    <td className="px-4 py-3 border-b-2 border-blue-gray-50 text-left">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-sm capitalize "
                      >
                        N/A
                      </Typography>
                    </td>
                    <td className="px-4 py-3 border-b-2 border-blue-gray-50 text-left">
                      <div className="flex flex-row items-center gap-x-2">
                        <IconButton
                          onClick={() => {
                            setselectedBranch(item?.id)
                            onSubmit(item?.id, "branch")
                          }}
                          color="green"
                          size="sm"
                        >
                          {selectedBranch === item?.id && requestLoading ? (
                            <Spinner className="w-4 h-4" />
                          ) : (
                            <FaArrowRight />
                          )}
                        </IconButton>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {loadingBranch && (
              <div className="mt-10 flex items-center justify-center">
                <Spinner className="w-1o h-10" />
              </div>
            )}
          </div>

          <div className="capitalize bg-white rounded-b-xl mb-5">
            <div className="p-3 ">
              <div className="w-full mr-3">
                <Input
                  crossOrigin="anonymous"
                  label="Search"
                  value={divisionquery}
                  onChange={handleDivisionSearch}
                  icon={<i className="fas fa-search text-base"></i>}
                />
              </div>
            </div>
            <table className=" bg-white w-full min-w-max table-auto text-left">
              <thead className="">
                <tr>
                  {["Divisions", "Description", ""].map((head) => (
                    <th
                      key={head}
                      className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                    >
                      <Typography
                        color="black"
                        className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {paginatedDivision?.map((item) => (
                  <tr
                    // onClick={() => {
                    //   if (selectedDivision === item?.id) {
                    //     setselectedDivision(null)
                    //   } else {
                    //     setselectedDivision(item?.id)
                    //   }
                    // }}
                    key={item.id}
                    className={`transition-all hover:cursor-pointer hover:bg-gray-100 `}
                  >
                    <td className="px-4 py-3 border-b-2 border-blue-gray-50 text-left">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-sm capitalize "
                      >
                        {item?.name}
                      </Typography>
                    </td>
                    <td className="px-4 py-3 border-b-2 border-blue-gray-50 text-left">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-sm capitalize "
                      >
                        {item?.description}
                      </Typography>
                    </td>
                    <td className="px-4 py-3 border-b-2 border-blue-gray-50 text-left">
                      <div className="flex flex-row items-center cursor-pointer gap-x-2">
                        <IconButton
                          onClick={() => {
                            setselectedDivision(item?.id)
                            onSubmit(item?.id, "division")
                          }}
                          color="green"
                          size="sm"
                        >
                          {selectedDivision === item?.id && requestLoading ? (
                            <Spinner className="w-4 h-4" />
                          ) : (
                            <FaArrowRight />
                          )}
                        </IconButton>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {loadingDivisions && (
              <div className="mt-10 flex items-center justify-center">
                <Spinner className="w-1o h-10" />
              </div>
            )}
          </div>

          {/* <div className="mt-5 flex items-end justify-end">
            <Button
              onClick={() => onSubmit()}
              size="sm"
              style={{
                background:
                  colors?.length! > 1
                    ? `${colors![0]?.toLowerCase()}`
                    : `${DEFAULTPRIMARYCOLOR}`,
              }}
              className="capitalize flex flex-row items-center font-normal"
            >
              <BiPlus size={20} />
              {requestLoading ? <Spinner className="w-4 h-4" /> : "Add"}
            </Button>
          </div> */}
        </div>

        {/*  */}
        <div className="flex flex-col space-y-4 w-full rounded-lg lg:col-span-2 overflow-hidden">
          <div className="w-full rounded-lg lg:col-span-2 overflow-hidden">
            <div className="bg-black p-3 text-white">
              <Typography className="text-lg">
                List of {selectedAgent?.name}'s Branches and Divisions
              </Typography>
            </div>
            <div className="p-3 bg-white rounded-b-xl">
              <CardBody className="shadow-none overflow-x-scroll px-0 py-0  rounded-none">
                <div className="p-3">
                  <div className="w-full mr-3">
                    <Input
                      crossOrigin="anonymous"
                      label="Search"
                      value={searchText}
                      onChange={handleSearch}
                      icon={<i className="fas fa-search text-base"></i>}
                    />
                  </div>
                </div>
                <table className=" bg-white w-full min-w-max table-auto text-center">
                  <thead className="">
                    <tr>
                      {[
                        "no",
                        "Branch code",
                        "Branch",
                        "Division",
                        "Division Description",
                        "",
                      ].map((head) => (
                        <th
                          key={head}
                          className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                        >
                          <Typography
                            color="black"
                            className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                          >
                            {head}
                          </Typography>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {paginatedData?.map((item, index: number) => {
                      const isLast = index === paginatedData?.length - 1
                      const classes = isLast
                        ? "px-4 py-2  text-center"
                        : "px-4 py-2 border-b-2 border-blue-gray-50 text-center"

                      return (
                        <tr
                          key={index.toString()}
                          className="hover:bg-gray-100 transition-all even:bg-gray-200"
                        >
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize "
                            >
                              {index}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize "
                            >
                              {`${item?.branch?.code}`}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize "
                            >
                              {`${item?.branch?.name}`}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize "
                            >
                              {`${item?.division?.name}`}
                            </Typography>
                          </td>

                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize "
                            >
                              {`${item?.division?.description}`}
                            </Typography>
                          </td>

                          <td className={classes}>
                            <div className="flex flex-row items-center gap-x-2">
                              <IconButton
                                onClick={() => handleDelete(item?.id)}
                                color="red"
                                size="sm"
                              >
                                <BiTrash />
                              </IconButton>
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </CardBody>

              {loading && (
                <div className="mt-10 flex items-center justify-center">
                  <Spinner className="w-1o h-10" />
                </div>
              )}

              {!paginatedData && !loading && (
                <div className="mt-10 flex items-center justify-center">
                  <Typography color="black" variant="h6">
                    No data found
                  </Typography>
                </div>
              )}

              {/* Paginator */}
              {paginatedData && paginatedData?.length !== 0 && !loading && (
              // <GeneralPagination
              //   color="black"
              //   totalItems={totalItems?.length!}
              //   itemsPerPage={itemsPerPage}
              //   currentPage={currentPage}
              //   onPageChange={handlePageChange}
              // />

              <WhitePaginateComponent
              totalPages={totalItems?.length!}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              perPage={itemsPerPage}
            />
            )}
            </div>
          </div>

          <div className="w-full rounded-lg lg:col-span-2 overflow-hidden">
            <div className="bg-black p-3 text-white">
              <Typography className="text-lg">
                List of {selectedAgent?.name}'s Commissions
              </Typography>
            </div>
            <div className="p-3 bg-white rounded-b-xl">
              <CardBody className="shadow-none overflow-x-scroll px-0 py-0  rounded-none">
                <div className="p-3 flex ">
                  <div className="w-full mr-3">
                    <Input
                      crossOrigin="anonymous"
                      label="Search"
                      value={searchTextCom}
                      onChange={handleSearchCom}
                      icon={<i className="fas fa-search text-base"></i>}
                    />
                  </div>

                  <div className="flex items-end justify-end">
                    <Button
                      onClick={() => setShowModal(true)}
                      size="sm"
                      style={{
                        background:
                          colors?.length! > 1
                            ? `${colors![0]?.toLowerCase()}`
                            : `${DEFAULTPRIMARYCOLOR}`,
                      }}
                      className="capitalize flex flex-row items-center font-normal"
                    >
                      <BiPlus size={20} />
                      {requestLoading ? <Spinner className="w-4 h-4" /> : "Add"}
                    </Button>
                  </div>
                </div>
                <table className=" bg-white w-full min-w-max table-auto text-center">
                  <thead className="">
                    <tr>
                      {["no", "Commission Type", "Payment mode", ""].map(
                        (head) => (
                          <th
                            key={head}
                            className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                          >
                            <Typography
                              color="black"
                              className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                            >
                              {head}
                            </Typography>
                          </th>
                        )
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {commissionsData &&
                      paginatedDataCom?.map(
                        (item: any, index) => {
                          const isLast =
                            index ===
                            commissionsData.user_commission_setup.length
                          const classes = isLast
                            ? "px-4 py-2  text-center"
                            : "px-4 py-2 border-b-2 border-blue-gray-50 text-center"

                          return (
                            <tr
                              key={index.toString()}
                              className="hover:bg-gray-100 transition-all even:bg-gray-200"
                            >
                              <td className={classes}>
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal text-xs capitalize "
                                >
                                  {index}
                                </Typography>
                              </td>
                              <td className={classes}>
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal text-xs capitalize "
                                >
                                  {`${item?.commision?.name}`}
                                </Typography>
                              </td>
                              {/* <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize "
                            >
                              {"10%"}
                            </Typography>
                          </td> */}

                              <td className={classes}>
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal text-xs capitalize "
                                >
                                  {`${item?.settlement_mode}`}
                                </Typography>
                              </td>

                              <td className={classes}>
                                <div className="flex flex-row items-center gap-x-2">
                                  <IconButton
                                    onClick={() => deleteClass(item?.id)}
                                    color="red"
                                    size="sm"
                                  >
                                    <BiTrash />
                                  </IconButton>
                                </div>
                              </td>
                            </tr>
                          )
                        }
                      )}
                  </tbody>
                </table>
              </CardBody>

              {loadingCommissions && (
                <div className="mt-10 flex items-center justify-center">
                  <Spinner className="w-10 h-10" />
                </div>
              )}

              {!paginatedDataCom && !loadingCommissions && (
                <div className="mt-10 flex items-center justify-center">
                  <Typography color="black" variant="h6">
                    No data found
                  </Typography>
                </div>
              )}

              {/* Paginator */}
              {paginatedDataCom && paginatedData?.length !== 0 && !loadingCommissions && (
              // <GeneralPagination
              //   color="black"
              //   totalItems={totalItems?.length!}
              //   itemsPerPage={itemsPerPage}
              //   currentPage={currentPage}
              //   onPageChange={handlePageChange}
              // />

              <WhitePaginateComponent
              totalPages={totalItemsCom?.length!}
              currentPage={currentPageCom}
              setCurrentPage={setCurrentPageCom}
              perPage={itemsPerPageCom}
            />
            )}
            </div>
          </div>
        </div>
      </div>
      <div className="h-20" />
    </>
  )
}

export default ManageAgent
